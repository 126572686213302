import React from 'react'
import loadable from '@loadable/component'
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from "../components/seo"

const ValuationBanner = loadable(() => import("../components/Banner/ValuationBanner"))
const GoogleReviews = loadable(() => import("../components/GoogleReviews/GoogleReviews"))
const WhatDoYouNeed = loadable(() => import("../components/WhatDoYouNeed/WhatDoYouNeed"))
const WhyChoose = loadable(() => import("../components/WhyChoose/WhyChoose"))
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const StatsModule = loadable(() => import("../components/StatsModule/StatsModule"))
const RecentProperties = loadable(() => import("../components/RecentProperties/RecentProperties"))
const FeaturedProperties = loadable(() => import("../components/RecentProperties/FeaturedProperties"))
const AccordionModule = loadable(() => import("../components/AccordionModule/AccordionModule"))
const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))

const Valuation = ({ data }) => {
    const PageData = data?.strapiPage

    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.valuation-module" && <ValuationBanner
                            breadcrumbData={PageData.choose_menu[0]}
                            pagename={PageData.title}
                            {...PageData.banner}
                            title={PageData.banner?.banner_title}
                            id={PageData.strapi_id}
                            imagetransforms={PageData.imagetransforms}
                            valData={module}
                            ggfx_results={PageData?.ggfx_results}
                        />}
                        {module.strapi_component === "page-modules.faq" && <AccordionModule {...module} />}
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties_sold" && <RecentProperties {...module} type="sold" />}
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties_let" && <RecentProperties {...module} type="let" />}
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties" && <FeaturedProperties {...module} />}
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_review_badge" && <GoogleReviews />}
                        {module.strapi_component === "page-modules.services-tiles" && <WhatDoYouNeed id={PageData.strapi_id} {...module} ggfx_results={PageData?.ggfx_results} />}
                        {module.strapi_component === "page-modules.image-and-content" && module.image_alignment === "left" ? <TileBlock id={PageData.strapi_id} {...module} ggfx_results={PageData?.ggfx_results} /> : module.strapi_component === "page-modules.image-and-content" && <WhyChoose id={PageData.strapi_id} {...module} ggfx_results={PageData?.ggfx_results} />}
                        {module.strapi_component === "page-modules.statistics" && <><Container>
                            <Row>
                                <Col>
                                    <div className="border-line"></div>
                                </Col>
                            </Row>
                        </Container>

                            <StatsModule {...module} />
                        </>
                        }
                        {/* {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties" && <RecentProperties {...module} />} */}
                        {module.strapi_component === "page-modules.plain-content" && module.layout === "bg_color_center_align" && <SellLetHome {...module} />}
                    </>
                )
            }
            )}
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}
export default Valuation
export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        add_page_modules {
              ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                  ...GlobalModuleFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
                  ...ImageAndContentFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_SERVICES_TILES {
                  ...ServicesTilesFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_STATISTICS {
                  ...StatsFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_MODULE {
                  ...ValuationModuleFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_FAQ {
                  ...FAQFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                ...PlainContentFragment
            }
        }
    }
  }
`